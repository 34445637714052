@import './variables.scss';

// Flex views
.flex-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

.flex-6 {
  flex: 6;
}

// fonts
.font-xs {
  font-size: $font-size-xs;
}
.font-sm {
  font-size: $font-size-sm;
}
.font-md {
  font-size: $font-size-md;
}
.font-lg {
  font-size: $font-size-lg;
}
.font-xl {
  font-size: $font-size-xl;
}
.font-xxl {
  font-size: $font-size-xxl;
}
.font-bold {
  font-weight: bold;
}

// utils

.clicable {
  &:hover {
    cursor: pointer;
  }
}
