// Vendors
// @import 'bootstrap';
@import 'nhsuk-frontend/packages/nhsuk';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// Core
@import 'core/colors';
@import 'core/fonts';
@import 'core/variables';
@import 'core/buttons';
@import 'core/heading';
@import 'core/utils';
@import 'core/typography';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  height: 100%;
  color: $color-primary-blue-dark;

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

a {
  text-decoration: underline;
}

p.nhsuk-error-color {
  color: #d5281b;
}

// Maybe can be remplaced from color var
.nhsuk-button,
.nhsuk-button:active {
  background-color: $color-primary-purple;
}
.nhsuk-button:hover {
  background-color: #817ab9;
}

.video-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
