@import './variables.scss';

.btn {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  // color: $color-primary-white;
  padding: 12px;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  &.btn-primary {
    background-color: $color-primary-green;
    color: $color-primary-white;
    border: 0;
    border-radius: 4px;

    &:active,
    &:focus {
      background-color: $color-primary-green-strong !important;
      border-color: $color-primary-grey !important;
      outline: 0;
      box-shadow: none !important;
    }
  }
  &.btn-secondary {
    color: $color-primary-green;
    background-color: $color-primary-white;
    border: 0;
    border-radius: 4px;

    &:active {
      background-color: $color-primary-green !important;
    }
    &:focus {
      outline: 0;
      box-shadow: none !important;
    }
  }
}
