@import 'scss/core/variables.scss';

.BaseLayout {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
    overflow: auto;
  }
}
