@import './variables.scss';

h1 {
  font-size: $font-size-xxl;
}

h2 {
  font-size: $font-size-xl;
}

h3 {
  font-size: $font-size-lg;
}

h4 {
  font-size: $font-size-md;
}
