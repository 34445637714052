@font-face {
  font-family: AvertaRegular;
  src: url('../../assets/fonts/Averta-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

html {
  #root {
    font-family: AvertaRegular, 'arial', sans-serif;
  }
}
