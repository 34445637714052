// .container-images {
//   .nhsuk-image {
//     width: 30%;
//     margin-bottom: 0;
//     margin-top: 0;
//   }
// }

header.header div {
  justify-content: space-between;

  img {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
